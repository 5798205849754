@charset "UTF-8";

//component

@import "core/brackpoint"; //ブレイクポイント設定

body{
  background: #fff;
}
header[role="header"]{
  padding: 2% 0;

  .header-nav{
    nav{
      ul{
        li{
          font-family: quiet-sans, sans-serif;
        }
      }
    }
  }
}

.worksList{
  width: 90%;
  //max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 85px;
  display: flex;
  flex-wrap: wrap;

  @include mq-sp {
    padding-top: 15vw;
    padding-bottom: 20%;
  }
  a{
    &:hover{
      i{
        opacity: 0.6;
        transform: scale(1.1);
      }
    }
  }
  & > div{
    width: 200px;
    margin-right: 35px;
    background: #fff;

    @include mq-sp {

      margin-bottom: 8%;
      width: 28vw;
      margin-right: 2vw;
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    a{
      display: block;
      figure{
        width: 100%;
        height: 200px;
        transition: 0.5s;
        overflow: hidden;

        @include mq-sp {
          height: 28vw;
        }
        i{
          opacity: 1;
          transition: 0.5s;
          display: block;
          width: 100%;
          height:100%;
          background-size: cover;
          background-position: center;
        }
      }
      h2{
        color: #000;
        font-size: 16px;
        font-weight: 500;
        font-family: quiet-sans, sans-serif;
        @include mq-sp {
          font-size: 4.2vw;
        }
      }
      p{
        font-size: 12px;
        color: #808080 !important;;
        @include mq-sp {
          font-size: 2vw;
        }
        a{
          color: #808080 !important;
        }
      }
    }

  }
}

.container{
  width: 90%;
  padding: 0;
}
.gnav{
width: 90%;
margin: 0 auto;
margin-bottom: 3%;
ul{
  display: flex;
  align-items: center;
  li{
    font-family: quiet-sans, sans-serif;
    font-weight: 700;
    width: 10%;
   list-style: none;
   font-size: 17px;

   @include mq-sp {
     font-size: 3.3vw;
     margin-right: 5%;
     width: auto;
   }

   @include mq-tb {
     font-size: 3.3vw;
     margin-right: 5%;
     width: auto;
   }
   a{
     letter-spacing: 2px;
     color: #000000;
   }
  }
}
}
main[role="main-home-wrapper"],
main[role="main-inner-wrapper"]{
width: 90%;
padding: 0;
}
.row{
  margin: 0;
}
.work-details{
  padding: 0;
  margin: 0;
  .work-images{
    width: 90%;
    margin-top: 0;
  }
  header{
    h2{
      font-size: 20px !important;
      font-family: quiet-sans, sans-serif;
      font-weight: 500;
    }
  }

}

main[role="main-inner-wrapper"]{
width: 90%;
margin: 0 0 0 5%;


}

._containar{
  width: 800px;
  margin-left: 5%;

  @include mq-sp {
    width: 90%;
    margin: 0 auto;
  }

  @include mq-tb {
    width: 90%;
    margin: 0 auto;
  }

  h2{
    font-size: 20px;
    font-family: quiet-sans, sans-serif;
    font-weight: 500;
    margin-top: 55px;

    @include mq-sp {
      font-size: 4vw;
      margin-top: 8vw;
    }

    @include mq-tb {
      font-size: 3vw;
      margin-top: 5vw;
    }
  }
  ul{
    li{
      margin-top: 35px;
      list-style: none;
      transition: 0.5s;
      opacity: 0;
      position: relative;
      transform: translateY(100px);
      img{
        width: 100%;
      }

      &._onview{
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
}
