//break point
@mixin mq-pc-tb {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin mq-sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin mq-pc {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin mq-pc-l {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin mq-tb {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}



@include mq-pc-tb {
}

@include mq-pc {
}

@include mq-sp {
}

@include mq-tb {
}
