body {
  background: #fff;
}

header[role="header"] {
  padding: 2% 0;
}

header[role="header"] .header-nav nav ul li {
  font-family: quiet-sans, sans-serif;
}

.worksList {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 85px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .worksList {
    padding-top: 15vw;
    padding-bottom: 20%;
  }
}

.worksList a:hover i {
  opacity: 0.6;
  transform: scale(1.1);
}

.worksList > div {
  width: 200px;
  margin-right: 35px;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .worksList > div {
    margin-bottom: 8%;
    width: 28vw;
    margin-right: 2vw;
  }
  .worksList > div:nth-child(3n) {
    margin-right: 0;
  }
}

.worksList > div a {
  display: block;
}

.worksList > div a figure {
  width: 100%;
  height: 200px;
  transition: 0.5s;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .worksList > div a figure {
    height: 28vw;
  }
}

.worksList > div a figure i {
  opacity: 1;
  transition: 0.5s;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.worksList > div a h2 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-family: quiet-sans, sans-serif;
}

@media screen and (max-width: 767px) {
  .worksList > div a h2 {
    font-size: 4.2vw;
  }
}

.worksList > div a p {
  font-size: 12px;
  color: #808080 !important;
}

@media screen and (max-width: 767px) {
  .worksList > div a p {
    font-size: 2vw;
  }
}

.worksList > div a p a {
  color: #808080 !important;
}

.container {
  width: 90%;
  padding: 0;
}

.gnav {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 3%;
}

.gnav ul {
  display: flex;
  align-items: center;
}

.gnav ul li {
  font-family: quiet-sans, sans-serif;
  font-weight: 700;
  width: 10%;
  list-style: none;
  font-size: 17px;
}

@media screen and (max-width: 767px) {
  .gnav ul li {
    font-size: 3.3vw;
    margin-right: 5%;
    width: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .gnav ul li {
    font-size: 3.3vw;
    margin-right: 5%;
    width: auto;
  }
}

.gnav ul li a {
  letter-spacing: 2px;
  color: #000000;
}

main[role="main-home-wrapper"],
main[role="main-inner-wrapper"] {
  width: 90%;
  padding: 0;
}

.row {
  margin: 0;
}

.work-details {
  padding: 0;
  margin: 0;
}

.work-details .work-images {
  width: 90%;
  margin-top: 0;
}

.work-details header h2 {
  font-size: 20px !important;
  font-family: quiet-sans, sans-serif;
  font-weight: 500;
}

main[role="main-inner-wrapper"] {
  width: 90%;
  margin: 0 0 0 5%;
}

._containar {
  width: 800px;
  margin-left: 5%;
}

@media screen and (max-width: 767px) {
  ._containar {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  ._containar {
    width: 90%;
    margin: 0 auto;
  }
}

._containar h2 {
  font-size: 20px;
  font-family: quiet-sans, sans-serif;
  font-weight: 500;
  margin-top: 55px;
}

@media screen and (max-width: 767px) {
  ._containar h2 {
    font-size: 4vw;
    margin-top: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  ._containar h2 {
    font-size: 3vw;
    margin-top: 5vw;
  }
}

._containar ul li {
  margin-top: 35px;
  list-style: none;
  transition: 0.5s;
  opacity: 0;
  position: relative;
  transform: translateY(100px);
}

._containar ul li img {
  width: 100%;
}

._containar ul li._onview {
  opacity: 1;
  transform: translateY(0px);
}
